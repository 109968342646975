/**This file contains a constant object that has properties that are necessary
 * for the build found in the `development` configuration in `angular.json`.
 */
export const environment = {
	apiTestingEnabled: false,
	production: false,
	backend_url: 'https://api-dev.int.vehicle.ford.com/dp',
	version: '1.21.2',
	msalConfig: {
		clientId: '3de6e9a7-e7f8-47cd-84b8-d8e254293d1a',
		authority: 'https://login.microsoftonline.com/c990bb7a-51f4-439b-bd36-9c07fb1041c0/',
		redirectUri: 'https://wwwqa.dp.ford.com'
	},
	apiConfig: {
		DevOpsPulse: {
			scopes: ['api://790c299f-e71c-4676-b282-14e2a41bb482/.default'],
			uri: 'https://api-dev.int.vehicle.ford.com/dp'
		}
	}
	// include development api base url string here as a property
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
